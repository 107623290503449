<!--
 * @Author: 羡国柱
 * @Date: 2023-07-18 14:58:13
 * @LastEditors: 羡国柱
 * @LastEditTime: 2023-07-18 15:07:38
 * @FilePath: \src\components\scrollWrapper\SEduArchives\Sub\ProgrammeEdit.vue
 * @Description: 智慧教学 > 综评档案 > 方案配置 -- 编辑
-->
<template>
	<div class="programme-edit-pages">
		<el-form  label-position="left" :label-width="formLabelWidth" :model="dengmiDataEdit" :rules="editRules"
			ref="dengmiDataEdit"  size="small" v-loading="dengmiDataEdit.loading">
			<el-form-item label="方案名称" prop="title" >
				<el-input v-model="dengmiDataEdit.title" name="title" placeholder="请输入方案名称" clearable></el-input>
			</el-form-item>
			<el-form-item label="选择年级" prop="grade">
				<el-checkbox-group v-model="dengmiDataEdit.grade" @change="getEditGradeInfo">
					<el-checkbox v-for="item in dengmiDataEdit.gradeOptions" :label="item.id" :key="item.id" >
						{{ item.name }}
					</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="关联考试" prop="examList" class="exam-wrap">
				<el-select v-model="dengmiDataEdit.examList" multiple collapse-tags placeholder="关联的考试" @visible-change="getEditExasmInfoer"
					@remove-tag="removeEditExasmInfo" ref="exasmSelectBox">
					<el-option v-for="item in dengmiDataEdit.examOptions" :key="item.id" class="programme-edit-exam-wrap-option" :label="item.examName" :value="item.id"></el-option>
				</el-select>
				
				<el-collapse-transition>
					<div class="exam-wraper-list" v-if="dengmiDataEdit.examListBoes">
						<div class="exam-wraper-list-box">
							<div v-for="item in dengmiDataEdit.examDataList" :key="item.examId"
								class="exam-wraper-list-cell" @mouseenter="isEditOperateShow(item)" @mouseleave="isEditOperateHide">
								<div class="exams-title">
									<span :title="item.examName">{{ item.examName }}</span>
									<i class="el-icon-error" @click="deleteEditExam(item)"></i>
								</div>
								<div class="exams-radio">
									<el-checkbox v-model="item.radio" @change="sval => getEditExamRadio(sval, item)">有附加分</el-checkbox>
								</div>
							</div>
						</div>
						<div class="exam-wraper-arrows">
							<i class="el-icon-caret-top"></i>
						</div>
					</div>
				</el-collapse-transition>
			</el-form-item>
			<div style="height: 150px;" v-show="dengmiDataEdit.hidenHeight"></div>
			<div>
				<div v-for="(item, index) in dengmiDataEdit.appendPList" :key="item.id">
					<div v-if="item.isAddScore === '1' && item.examName" style="color: 16px; font-size: 16px; font-weight: 600; padding-left: 3px; padding-bottom: 24px;">
						{{ '【' + item.examName  + '】'}}关联行为设置
					</div>
					<div v-if="item.isAddScore === '1'">
						<el-form-item label="起止日期" :prop="'appendPList.' + index + '.detaTime'" 
							:rules="{required: true, message: '请选择起止日期', trigger: 'blur'}">
							<el-date-picker v-model="item.detaTime" clearable type="daterange"
								range-separator="至" value-format="yyyy-MM-dd" start-placeholder="起始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item :prop="'appendPList.' + index + '.tableData'" :rules="{required: true, message: '请选择行为', trigger: ['change', 'blur']}">
							<el-button type="primary" @click="targetShowEditClick(item)">选择行为</el-button>
						</el-form-item>
						<el-form-item >
							<el-table
								style="width: 100%"
								border stripe size="mini"
								:data="item.tableData"
								:header-cell-style="{ background:'#F1F1F1',color:'#2B2F33' }">
								<el-table-column label="序ㅤ号" type="index" align="center" width="65px"></el-table-column>
								<el-table-column align="left" label="行ㅤ为" show-overflow-tooltip >
									<template slot-scope="scope">
										<span>{{ scope.row.ruleName }}</span>
									</template>
								</el-table-column>
								<el-table-column align="center" label="运ㅤ算" width="65px">
									<template slot-scope="scope">
										<span v-if="scope.row.ruleType === '1'" style="color: #20EDDC;">-</span>
										<span v-if="scope.row.ruleType === '2'" style="color: #ED2020;">+</span>
									</template>
								</el-table-column>
								<el-table-column align="center" label="分ㅤ值" width="100px">
									<template slot-scope="scope">
										<span>{{ scope.row.score }}</span>
									</template>
								</el-table-column>
								<div slot="empty">
									<p :style="{'marginTop': '23px'}">暂无数据</p>
								</div>
							</el-table>
						</el-form-item>
					</div>
				</div>
			</div>
			<el-form-item >
				<div style="text-align: right; margin-top: 50px;">
					<el-button :disabled="disabledBtnState" @click="examEditCancel">取消</el-button>
					<el-button type="primary" :disabled="disabledBtnState" @click="examEditConfirm('dengmiDataEdit')">确定</el-button>
				</div>
			</el-form-item>
		</el-form>
		<dialog-wrapper :dialog-obj="dialogTargetObj" @handleClose="handleTargetClose">
			<div class="target-list">
				<div style="display: flex;">
					<div class="target-list-query">
						<el-input v-model="targetName" clearable placeholder="行为名称"></el-input>
					</div>
					<div class="target-list-number">
						<span>已选行为</span>
						<span>{{ dialogTargetObj.targetSeleteList.length }}</span>
					</div>
				</div>
				
				<div class="target-list-boes">
					<div class="target-list-boes-row target-list-boes-lt">
						<el-tree ref="treeEdit" show-checkbox node-key="id" 
							:data="dialogTargetObj.targetDataList" 
							:props="dialogTargetObj.defaultProps"
							:default-expanded-keys="dialogTargetObj.expandedKeys"
							:default-checked-keys="dialogTargetObj.checkedkeys"
							:filter-node-method="filterNode"
							@node-click="handleNodeClick" 
							@check-change="handleCheckChange">
						</el-tree>
					</div>
					<div class="target-list-boes-row target-list-boes-rt">
						<div v-for="(item, index) in dialogTargetObj.targetSeleteList" :key="index" class="target-list-boes-rt-row">
							<div class="target-selete-cell">
								<div class="target-selete-cell-delete" @click="targetEditDelete(item)">
									<i class="el-icon-error"></i>
								</div>
								<div class="target-selete-cell-name">{{ item.ruleName }}</div>
							</div>
						</div>
					</div>
				</div>
				<div style="text-align: right; margin-top: 20px;">
					<el-button @click="targetSeleEditCancel">取消</el-button>
					<el-button type="primary" @click="targetSeleEditConfirm">保存</el-button>
				</div>
			</div>
		</dialog-wrapper>
	</div>
</template>

<script>
	import {
		// 业务组件
		FilterData,
		TableData,
		DialogWrapper,
		Pagination,
		// 功能组件
		Error,
		Loading,
		// 工具函数
		throttle,
	} from "common-local";
	import {
		mapState
	} from 'vuex';
	import {
		hasPermission
	} from "@/libs/utils";
	import ExpandFilter from "../../Sub/ExpandFilter";
	import { formatTreeData, treeFind, treeForeach } from "@/libs/utils";
	
	export default {
		name: 'ProgrammeEdit',
		components: {
			FilterData,
			TableData,
			Pagination,
			DialogWrapper,
			// 功能组件
			Error,
			Loading,
			ExpandFilter
		},
		props: {
			dialogEditObj: {
				type: Object
			}
		},
		data() {
			return {
				formLabelWidth: '100px',
				disabledBtnState: false,
				isOperate: '',
				dengmiDataEdit: {
					title: '',
					schemeId: '',
					grade: [],
					gradeOptions: [],
					examListBox: false,
					examListBoes: false,
					hidenHeight: false,
					addState: false,
					loading: true,
					exam: '',
					examList: [],
					examEditList: [], 
					examDataList: [],
					examOptions: [],
					appendPList: [],
					examSeleteList: []
				},
				editRules: {
					title: [{
						required: true, 
						message: "请输入方案名称", 
						trigger: "blur"
					}],
					grade: [{
						required: true, 
						message: "请选择年级", 
						trigger: "blur"
					}],
					examList: [{
						required: true,
						message: "添加关联的考试",
						trigger: "blur"
					}],
					exam: [{
						required: true, 
						message: "添加关联的考试", 
						trigger: "blur"
					}]
				},
				// 搜索指标
				targetName: '',
				dialogTargetObj: {
					title: '选择行为',
					dialogVisible: false,
					dataId: '',
					width: '600px',
					defaultProps: {
						value: 'id',
						label: 'ruleName',
						children: 'children'
					},
					targetSeleteList: [],
					targetDataList: [],
					// 默认展开
					expandedKeys: [],
					// 默认选中的数据
					checkedkeys: []
				}
			}
		},
		computed: {
			...mapState({
				permissions: (state) => state.permissions
			})
		},
		created() {
			this.init();
		},
		mounted() {
			// console.log('id================ ',this.dialogEditObj.id)
		},
		watch: {
			'dialogEditObj.keyId'(newVal, oldVal) {
				//console.log(`新值：${newVal}`);
				//console.log(`旧值：${oldVal}`);
				//console.log('id================ ',this.dialogEditObj.id)
				this.init();
			},
			targetName(val) {
				treeEdit
				this.$refs.treeEdit.filter(val);
			}
		},
		methods: {
			/**
			 * @Description: 加载
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:02:07
			 */
			init() {
				this.dengmiDataEdit.loading = true;
				this.handleDetailData();
				this.handleGradeList();
			},
			/**
			 * @Description:方案详情接口
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-25 14:50:21
			 */
			handleDetailData() {
				let id = this.dialogEditObj.id;
				let gradeList = [];
				let addScoreList = [];
				this.dengmiDataEdit.examList = [];
				this.dengmiDataEdit.grade = [];
				this.dengmiDataEdit.appendPList = [];
				this.dengmiDataEdit.examListBoes = false;
				this.dengmiDataEdit.hidenHeight = false;
				this._fet('/school/schoolCerProgramme/info/' + id, {
					
				}).then((res) => {
					if (res.data.code === '200') {
						let reg = res.data.data;
						let examList = res.data.data.examList;
						this.dengmiDataEdit.title = reg.name;
						this.dengmiDataEdit.schemeId = reg.id;
						
						if(examList && examList.length > 0) {
							for(let j = 0; j < examList.length; j++) {
								this.dengmiDataEdit.examList.push(examList[j].examId)
								// 有附加分的方案列表
								if(examList[j].isAddScore == 1) {
									addScoreList.push(examList[j]);
								}
								this.dengmiDataEdit.hidenHeight = true;
								this.dengmiDataEdit.examListBoes = true;
								examList.forEach(item => {
									let detaTime = [];
									if(item.startTime) {
										detaTime.push(item.startTime.substring(0,10), item.endTime.substring(0,10));
									}
									item.id = item.id;
									item.examId = item.examId;
									item.examName = item.examName;
									item.isAddScore = item.isAddScore;
									if(item.isAddScore === '1') {
										// item.radio = item.examId || '';
										item.radio = true;
									} else {
										item.radio = '';
									}
									
									item.startTime = item.startTime;
									item.endTime = item.endTime;
									item.detaTime = detaTime; 
									item.tableData = item.ruleList;
									item.ruleIds = item.ruleIds;
									item.ruleIds = item.ruleIds;
									item.gradeIds = JSON.parse(reg.gradeInfo)
								});
								console.log('获取全部考试方案346===== ', examList);
								// 获取全部考试方案
								this.dengmiDataEdit.examEditList = examList;
								this.dengmiDataEdit.examDataList = examList;
								// 附加分列表
								this.dengmiDataEdit.appendPList = examList;
							}
						}
						
						// 考试方案返现
						if(reg.gradeInfo) {
							gradeList = JSON.parse(reg.gradeInfo);
							for(let k = 0; k < gradeList.length; k++) {
								this.dengmiDataEdit.grade.push(gradeList[k]);
							}
							this.handleExamList(this.dengmiDataEdit.grade);
						}
						
						// if(addScoreList.length > 0) {
						// 	for(let k = 0; k < addScoreList.length; k++) {
						// 		this.dengmiDataEdit.appendPList.push({
						// 			id: addScoreList[k].id,
						// 			examId: addScoreList[k].examId,
						// 			examName: addScoreList[k].examName,
						// 			isAddScore: addScoreList[k].isAddScore,
						// 			startTime: addScoreList[k].startTime,
						// 			endTime: addScoreList[k].endTime,
						// 			ruleIds: addScoreList[k].ruleIds,
						// 			detaTime: [addScoreList[k].startTime, addScoreList[k].endTime],
						// 			tableData: addScoreList[k].ruleList
						// 		})
						// 	}
							
						// }
						
						setTimeout(()=>{
							this.dengmiDataEdit.loading = false;
						},900)
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description:获取年级信息列表
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:11:07
			 */
			handleGradeList() {
				this._fet('/school/schoolOrgan/listByCondition', {
					id: this.dialogEditObj.id,
					schoolId: this.$store.state.schoolId,
					organType: '3'
				}).then((res) => {
					if (res.data.code === '200') {
						this.dengmiDataEdit.gradeOptions = res.data.data.list;
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description:获取未关联考试列表
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:11:08
			 */
			async handleExamList(gradeIdList) {
				// 详情全部方案数据
				let dataEditSeleList = this.dengmiDataEdit.examEditList;
				console.log('详情全部方案数据=========',dataEditSeleList)
				await this._fet('/school/schoolCerProgramme/getNoJoinExamList', {
					id: this.dialogEditObj.id,
					gradeIdList: gradeIdList
				}).then((res) => {
					if (res.data.code === '200') {
						let reg = res.data.data;
						reg.forEach(item => {
							item.radio = '';
							dataEditSeleList.forEach(sitem => {
								if(item.id === sitem.examId) {
									console.log(sitem.isAddScore === null)
									if(sitem.isAddScore === null) {
										
									} else if(sitem.isAddScore === '1') {
										// item.radio = sitem.examId;
										item.radio = true;
									}
									
								}
							})
							item.id = item.id;
							item.examStatus = item.examStatus;
							item.sourceType = item.sourceType;
							item.gradeName = item.gradeName;
							item.examTypeName = item.examTypeName;
							item.examName = item.examName;
							item.gradeId = item.gradeId;
							item.gradeName = item.gradeName;
						});
						this.dengmiDataEdit.examOptions = reg;
						console.log('详情全部方案数据=========',this.dengmiDataEdit.examOptions)
						if(this.dengmiDataEdit.examOptions.length <= 0) {
							this.dengmiDataEdit.examListBox = false;
						}
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description:指标树形结构数据处理
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-27 16:57:15
			 */
			getsTreeData(data){
				for(let i = 0; i < data.length ; i++){
					if(data[i].behaviorList && data[i].behaviorList.length > 0){
						this.getsTreeData(data[i].behaviorList);
					}else {
						for(let j = 0; j < data.length ; j++) {
							data[j].disabled = false;
							if(data[j].type === '1' && data[j].behaviorList && data[j].behaviorList.length == 0) {
								data[j].disabled = true;
							} else if(data[j].type === '1') {
								data[j].disabled = true;
							}
						}
					}
				}
				return data;
			},
			/**
			 * @Description:学校指标行为列表接口
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:11:08
			 */
			handleTargetList() {
				let dataList = this.dengmiDataEdit.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				this.dialogTargetObj.expandedKeys = [];
				this.dialogTargetObj.checkedkeys = [];
				this._fet('/school/schoolMoralRule/listByCondition', {
					schoolId: this.$store.state.schoolId,
					ruleName: ''
				}).then((res) => {
					if (res.data.code === '200') {
						let reg = res.data.data;
						reg = this.getsTreeData(reg);
						reg = reg.sort((a, b) => {
							return a.sort - b.sort;
						});
						let targetDataList = formatTreeData(
							JSON.parse(JSON.stringify(reg)),
							'id',
							'parentId'
						);
						treeForeach(targetDataList, 'children', node => {
							if (node.behaviorList && node.behaviorList.length > 0) {
								node.children = node.behaviorList
							}
						})
						this.dialogTargetObj.targetDataList = targetDataList;

						for(let j = 0; j < reg.length; j++) {
							if(reg[j].behaviorList.length > 0) {
								this.dialogTargetObj.expandedKeys.push(reg[j].id)
							}
						}
						for(let k = 0; k < dataList.length; k++) {
							if(dataId === dataList[k].id) {
								for(let j = 0; j < dataList[k].tableData.length; j++) {
									// 默认展开
									// if(dataList[k].tableData[j].parentId) {
									// 	this.dialogTargetObj.expandedKeys.push(dataList[k].tableData[j].parentId)
									// }
									// 默认选中的数据
									this.dialogTargetObj.checkedkeys.push(dataList[k].tableData[j].id);
								}
								this.dialogTargetObj.targetSeleteList = dataList[k].tableData;
							}
						}
						
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description: 获取年级主键
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:22:10
			 */
			async getEditGradeInfo(val) {
				if(val.length > 0) {
					await this.handleExamList(val);
				} else {
					this.dengmiDataEdit.examOptions = [];
				}
                const ids = this.dengmiDataEdit.examOptions.map(i => i.id)
                this.dengmiDataEdit.examList = this.dengmiDataEdit.examList.filter(i => ids.includes(i))
                this.getEditExasmInfoer()
			},
			/**
			 * @Description: 关联考试下拉框显示/隐藏处理
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-08-12 08:55:16
			 */
			getEditExasmInfoer(e) {
				// 创建底部按钮
				let str = '';
				let examWrapBtn = document.createElement('div');
				examWrapBtn.setAttribute('class', 'exam-wrapers-btn');
				str += '<span class="btn-cell confirm-exam-btn " @click="examEditSeleConfirm">确定</span>';
				str += '<span class="btn-cell cancel-exam-btn" @click="examEditSeleCancel">取消</span>';
				
				examWrapBtn.innerHTML = str;
				if(e) { 
					this.dengmiDataEdit.hidenHeight = true;
					// 下拉框出现时的操作处理
					let elScrollEdit = document.getElementsByClassName('el-select-dropdown__wrap');
					// console.log('编辑 ',elScrollEdit)
					
					// 获取下拉框元素并将按钮插入
					let elScrollbarWrap = document.getElementsByClassName('el-select-dropdown__wrap')[elScrollEdit.length - 1];
					
					// 插入前先判断有没有按钮，有就先移除
					if(elScrollbarWrap.lastChild.getAttribute('class') === 'exam-wrapers-btn') {
						let examWraperBtn = elScrollbarWrap.getElementsByClassName('exam-wrapers-btn')[0];
						elScrollbarWrap.removeChild(examWraperBtn);
					}
					// 插入操作按钮
					elScrollbarWrap.appendChild(examWrapBtn)
					// 获取操作按钮元素
					let confirmExamBtn = document.getElementsByClassName('confirm-exam-btn')[0];
					let cancelExamBtn = document.getElementsByClassName('cancel-exam-btn')[0];
					
					// 关联考试选择确定
					confirmExamBtn.onclick = () => {
						this.examEditSeleConfirm();
					}
					// 关联考试选择取消
					cancelExamBtn.onclick = () => {
						this.examEditSeleCancel();
					}
				} else {
					// 下拉框隐藏时
                    let obj = {};
                    let examSeleList = [];
                    let dataSeleList = this.dengmiDataEdit.examList;
                    let dataList = this.dengmiDataEdit.examOptions;
                    console.log('全部方案 ', dataList)
                    console.log('关联考试选择确定 ', dataSeleList)

                    for(let j = 0; j < dataList.length; j++) {
                        for(let k = 0; k < dataSeleList.length; k++) {
                            if(dataList[j].id === dataSeleList[k]) {
                                examSeleList.push(dataList[j])
                                examSeleList.forEach(item => {
                                    item.examId = item.id;
                                    item.examName = item.examName;
                                    item.radio = item.radio;
                                    item.gradeId = item.gradeId;
                                    item.gradeName = item.gradeName;
                                    item.isAddScore = '';
                                });
                            }
                        }
                    }

                    console.log('关联考试选择确定后 ', examSeleList)
                    this.dengmiDataEdit.examDataList = examSeleList;
                    let selectedIds = this.dengmiDataEdit.examDataList.filter(e => e.radio).map(e => e.id)
                    console.log("[getExasmInfoer]", this.dengmiDataEdit.examDataList, selectedIds, this.dengmiDataEdit.examDataList)
                    this.dengmiDataEdit.appendPList = this.dengmiDataEdit.appendPList.filter(e => selectedIds.includes(e.examId || e.id))
                    this.dengmiDataEdit.examListBoes = this.dengmiDataEdit.examList.length > 0;
                    this.dengmiDataEdit.hidenHeight = this.dengmiDataEdit.examList.length > 0
				}
			},
			/**
			 * @Description: 下拉框多选获取删除项主键
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-08-13 14:37:58
			 */
			removeEditExasmInfo(val) {
				console.log('删除', val)
				let dataList = this.dengmiDataEdit.examDataList;
				let examOption = this.dengmiDataEdit.examOptions;
				
				for(let k = 0; k < examOption.length; k++) {
					if(val === examOption[k].id) {
						for(let j = 0; j < this.dengmiDataEdit.grade.length; j++) {
							if(this.dengmiDataEdit.grade[j] === examOption[k].gradeId) {
								this.dengmiDataEdit.grade.splice(j, 1)
							}
						}
					}
				}
				for(let j = 0; j < dataList.length; j++) {
					if(val === dataList[j].examId) {
						this.dengmiDataEdit.examDataList.splice(j, 1)
					}
				}
				
				if(this.dengmiDataEdit.appendPList.length > 0) {
					for(let j = 0; j < this.dengmiDataEdit.appendPList.length; j++) {
						if(val === this.dengmiDataEdit.appendPList[j].examId) {
							this.dengmiDataEdit.appendPList.splice(j, 1);
						}
					}
					
				}
				
				if(this.dengmiDataEdit.examDataList.length <= 0) {
					this.dengmiDataEdit.examListBoes = false;
					this.dengmiDataEdit.hidenHeight = false;
				}
			},
			/**
			 * @Description: 关联考试选择确定
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-08-12 09:56:59
			 */
			examEditSeleConfirm() {
                if(this.dengmiDataEdit.examList.length <= 0) {
                    this.$message({
                        type: 'warning',
                        message: '请选择关联考试！'
                    });
                    return false;
                }
				this.$refs.exasmSelectBox.blur();
			},
			/**
			 * @Description: 关联考试选择取消
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-08-12 10:06:35
			 */
			examEditSeleCancel() {
				console.log('关联考试选择取消')
				this.$refs.exasmSelectBox.blur();
				this.dengmiDataEdit.examListBoes = false;
				this.dengmiDataEdit.hidenHeight = false;
				this.dengmiDataEdit.examList = [];
				this.dengmiDataEdit.examDataList = [];
			},
			
			
			/**
			 * @Description: 关联考试显示获取附加分及删除操作
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:20
			 */
			isEditOperateShow(dataItem) {
				this.isOperate = dataItem.id;
			},
			/**
			 * @Description: 关联考试隐藏获取附加分及删除操作
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:28
			 */
			isEditOperateHide(dataItem) {
				this.isOperate = '';
			},
			/**
			 * @Description: 关联考试信息删除
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:38
			 */
			deleteEditExam(dataItem) {
				let dataObj = {};
				let datasObj = {};
				let index = this.dengmiDataEdit.examDataList.indexOf(dataItem);
				let dataList = this.dengmiDataEdit.examList;
				
				if (index !== -1) {
				  this.dengmiDataEdit.examDataList.splice(index, 1)
				}
				for(let j = 0; j < dataList.length; j++) {
					if(dataItem.examId === dataList[j]) {
						this.dengmiDataEdit.examList.splice(j, 1)
					}
				}
				
				for(let k = 0; k < this.dengmiDataEdit.grade.length; k++) {
					if(dataItem.gradeId === dataList[k]) {
						this.dengmiDataEdit.grade.splice(k, 1)
					}
				}
				
				
				if(this.dengmiDataEdit.appendPList.length > 0) {
					for(let j = 0; j < this.dengmiDataEdit.appendPList.length; j++) {
						if(dataItem.id === this.dengmiDataEdit.appendPList[j].id) {
							this.dengmiDataEdit.appendPList.splice(j, 1);
						}
					}
					
				}
				// 去重
				this.dengmiDataEdit.appendPList = this.dengmiDataEdit.appendPList.reduce(function(item, next) {
					   datasObj[next.examId] ? '' : datasObj[next.examId] = true && item.push(next);
					   return item;
					}, []);
				this.dengmiDataEdit.examDataList = this.dengmiDataEdit.examDataList.reduce(function(item, next) {
					   dataObj[next.examId] ? '' : dataObj[next.examId] = true && item.push(next);
					   return item;
					}, []);
				//console.log(this.dengmiDataEdit.examDataList)
				if(this.dengmiDataEdit.examDataList.length <= 0) {
					//console.log('12121 ',this.dengmiDataEdit.examDataList)
					// 关联考试为空时 隐藏选择弹窗
					this.dengmiDataEdit.hidenHeight = false;
					this.dengmiDataEdit.examListBoes = false;
				}
			},
			/**
			 * @Description: 获取关联考试信息附加分
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:47
			 */
			getEditExamRadio(val, dataItem) {
				let dataObj = {};
				let datasObj = {};
				let isAddScore = '';
				console.log('编辑时获取关联考试信息附加分val ',val)
				console.log('编辑时获取关联考试信息附加分dataItem ',dataItem)
				if(val) {
					isAddScore = dataItem.radio === '' ? '' : true;
					for(let k = 0; k < this.dengmiDataEdit.appendPList.length; k++) {
						if(dataItem.id === this.dengmiDataEdit.appendPList[k].id) {
							dataItem.radio = true;
						}
					}
					
					this.dengmiDataEdit.appendPList.push({
						id: dataItem.id,
						examName: dataItem.examName,
						examId: dataItem.examId,
						isAddScore: isAddScore,
						gradeId: dataItem.gradeId,
						gradeName: dataItem.gradeName,
						startTime: '',
						endTime: '',
						ruleIds: '',
						detaTime: [],
						tableData: []
					})
					
				} else {
					for(let k = 0; k < this.dengmiDataEdit.appendPList.length; k++) {
						if(dataItem.id === this.dengmiDataEdit.appendPList[k].id) {
							dataItem.radio = '';
							this.dengmiDataEdit.appendPList.splice(k, 1)
						}
					}
				}
				
				for(let j = 0; j < this.dengmiDataEdit.appendPList.length; j++) {
					if(this.dengmiDataEdit.appendPList[j].radio !== '') {
						this.dengmiDataEdit.appendPList[j].isAddScore = '1';
					}
				}
				// 去重
				this.dengmiDataEdit.appendPList = this.dengmiDataEdit.appendPList.reduce(function(item, next) {
					   datasObj[next.examId] ? '' : datasObj[next.examId] = true && item.push(next);
					   return item;
					}, []);
				// 去重
				this.dengmiDataEdit.examDataList = this.dengmiDataEdit.examDataList.reduce(function(item, next) {
					   dataObj[next.examId] ? '' : dataObj[next.examId] = true && item.push(next);
					   return item;
					}, []);
				
				
				console.log('编辑时获取关联考试信息附加分 ',this.dengmiDataEdit.appendPList)
				console.log('编辑时获取关联考试信息附加分 ',this.dengmiDataEdit.examDataList)
				
			},
			
			/**
			 * @Description: 指标选择弹窗
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 17:50:55
			 */
			targetShowEditClick(dataItem) {
				this.dialogTargetObj.dataId = dataItem.id;
				this.handleTargetList();
				this.dialogTargetObj.dialogVisible = true;
			},
			/**
			 * @Description: 指标选择
			 * @DoWhat: 
			 * @UseScenarios: 
			 * @Attention: 
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 16:18:05
			 */
			handleCheckChange(data, checked, indeterminate) {
				// 获取选中的数据
				let dataList = [];
				let dataLength = 0;
				dataList = this.$refs.treeEdit.getCheckedNodes(true);
				dataLength = dataList.length;
				this.dialogTargetObj.targetSeleteList = [];
				for(let j = 0; j < dataLength; j++) {
					this.dialogTargetObj.targetSeleteList.push(dataList[j]);
				}
				console.log('指标获取选中的数据=== ', this.dialogTargetObj.targetSeleteList)
			},
			/**
			 * @Description: 查询指标
			 * @DoWhat: 
			 * @UseScenarios: 
			 * @Attention: 
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 16:18:16
			 */
			filterNode(value, data) {
				if (!value) {
					return true;
				}
				return data.ruleName.indexOf(value) !== -1;
			},
			/**
			 * @Description: 指标选择
			 * @DoWhat: 
			 * @UseScenarios: 
			 * @Attention: 
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 16:18:18
			 */
			handleNodeClick(data, node, nodeData) {
				let num = 0;
				// this.dialogTargetObj.targetSeleteList.forEach(item => {
				// 	item == data.label ? num++ : num;
				// })
				// if(num == 0) {
				// 	this.dialogTargetObj.targetSeleteList.push(data.label)
				// }
				// console.log('指标获取选中的数据 ==495', this.dialogTargetObj.targetSeleteList)
			},
			/**
			 * @Description: 指标选择删除
			 * @DoWhat: 
			 * @UseScenarios: 
			 * @Attention: 
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 17:25:07
			 */
			targetEditDelete(dataItem) {
				let dataList = this.$refs.treeEdit.getCheckedNodes(true);
				let index = this.dialogTargetObj.targetSeleteList.indexOf(dataItem)
				if (index !== -1) {
				  this.dialogTargetObj.targetSeleteList.splice(index, 1)
				}
				// 删除左侧tree选中的节点
				this.$refs.treeEdit.setChecked(dataItem.id, false, true);
			},
			/**
			 * @Description: 指标选择弹窗关闭
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 18:18:35
			 */
			handleTargetClose() {
				let dataList = this.dengmiDataEdit.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				for(let j = 0; j < dataList.length; j++) {
					if(dataId === dataList[j].id) {
						if(dataList[j].tableData.length <= 0) {
							this.dialogTargetObj.targetSeleteList = [];
							this.$refs.treeEdit.setCheckedKeys([]);
						} 
					}
				}
				this.dialogTargetObj.dialogVisible = false;
			},
			/**
			 * @Description: 指标选择弹窗取消
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 18:18:35
			 */
			targetSeleEditCancel() {
				let dataList = this.dengmiDataEdit.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				for(let j = 0; j < dataList.length; j++) {
					if(dataId === dataList[j].id) {
						if(dataList[j].tableData.length <= 0) {
							this.dialogTargetObj.targetSeleteList = [];
							this.$refs.treeEdit.setCheckedKeys([]);
							
						} 
					}
				}
				this.dialogTargetObj.dialogVisible = false;
			},
			/**
			 * @Description: 指标选择弹窗保存
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 18:18:35
			 */
			targetSeleEditConfirm() {
				let dataList = this.dengmiDataEdit.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				for(let j = 0; j < dataList.length; j++) {
					if(dataId === dataList[j].id) {
						dataList[j].tableData = this.dialogTargetObj.targetSeleteList;
                        this.$refs.dengmiDataEdit.validateField(`appendPList.${j}.tableData`)
					}
				}
				this.dialogTargetObj.dialogVisible = false;
			},
			/**
			 * @Description: 新增方案取消
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 17:11:51
			 */
			examEditCancel() {
				this.dengmiDataEdit.examListBox = false;
				this.dengmiDataEdit.examListBoes = false;
				this.dengmiDataEdit.hidenHeight = false;
				this.dengmiDataEdit.title = '';
				this.dengmiDataEdit.grade = [];
				this.dengmiDataEdit.exam = '';
				this.dengmiDataEdit.appendPList = [];
				this.dengmiDataEdit.examEditList = [];
				
				this.dengmiDataEdit.examList = [];
				this.dengmiDataEdit.examDataList = [];
				
				this.$emit('change', 'NO')
			},
			/**
			 * @Description: 补0操作
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 陈金伟
			 * @Date: 2023-08-11 11:18:15
			 * @param {*} num
			 */
			padZero(num) {
				if (num < 10) {
					return '0' + num;
				}
				return num;
			},
			/**
			 * @Description: 时间转换字符串
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 陈金伟
			 * @Date: 2023-08-11 11:10:11
			*/
			DateToStringData(data,type){
				// 创建一个日期对象
				let date = new Date(data);
				if(type=='start'){
					// 将时、分、秒部分设置为0
					date.setHours(0);
					date.setMinutes(0);
					date.setSeconds(0);
				}else{
					// 将时、分、秒部分设置为0
					date.setHours(23);
					date.setMinutes(59);
					date.setSeconds(59);
				}
				// 获取日期对象中的年、月、日、时、分、秒部分
				var year = date.getFullYear();
				var month = date.getMonth() + 1; // 月份从0开始，需要加1
				var day = date.getDate();
				var hours = date.getHours();
				var minutes = date.getMinutes();
				var seconds = date.getSeconds();
				var formattedDate = year + '-' + this.padZero(month) + '-' + this.padZero(day) + ' ' + this.padZero(hours) + ':' + this.padZero(minutes) + ':' + this.padZero(seconds);
				return formattedDate
			},
			/**
			 * @Description: 新增方案确定
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 17:11:59
			 */
			examEditConfirm(formName) {
				let obj = {};
				let dataObj = {};
				let examList = [];
				let gradeInfoList = [];
				
				// 详情返回的数据 
				let dataList = this.dengmiDataEdit.appendPList;
				// 编辑时新增的数据
				let editDataList = this.dengmiDataEdit.examDataList;
				
				let examDataSeleteList = this.dengmiDataEdit.examList;
				let examDataOptions =  this.dengmiDataEdit.examOptions;
				
				console.log('编辑时新增的数据 ===', editDataList)
				console.log('详情返回的全部考试方案 ', dataList)
				
				dataList.forEach(item => {
					let ruleIdsList = [];
					let ruleIds = '';
					item.id = item.id;
					item.examName = item.examName;
					item.examId = item.examId;
					item.isAddScore = item.isAddScore;
					item.gradeId = item.gradeId;
					item.gradeName = item.gradeName;
					if(item.detaTime.length > 0) {
						item.startTime = this.DateToStringData(item.detaTime[0],'start');
						item.endTime = this.DateToStringData(item.detaTime[1],'end');
					}
					for(let j = 0; j < item.tableData.length; j++) {
						ruleIdsList.push(item.tableData[j].id)
					}
					if(ruleIdsList.length > 0) {
						ruleIds = ruleIdsList.join(',');
					}
					item.ruleIds = ruleIds;
				})
				
				examList = dataList;
				// console.log('editDataList====1111', editDataList)
				// 编辑时新增的数据
				if(editDataList.length > 0) {
					editDataList.map((item, index)=>{
						examList.push({
							id: item.id,
							examName: item.examName,
							examId: item.examId,
							isAddScore: item.isAddScore || '0',
							gradeId: item.gradeId,
							gradeName: item.gradeName,
							startTime: '',
							endTime: '',
							ruleIds: ''
						})
					})
				} else {
					examDataOptions.forEach(item => {
						examDataSeleteList.forEach(sitem => {
							if(item.id === sitem) {
								examList.push({
									examName: item.examName,
									examId: item.id,
									isAddScore: 0,
									startTime: '',
									endTime: '',
									ruleIds: ''
								})
							}
						})
					})
				}
				
				
				// 重复数据去重
				examList = examList.reduce(function(item, next) {
					   obj[next.examId] ? '' : obj[next.examId] = true && item.push(next);
					   return item;
					}, []);
					
				this.dengmiDataEdit.appendPList = this.dengmiDataEdit.appendPList.reduce(function(item, next) {
					   dataObj[next.examId] ? '' : dataObj[next.examId] = true && item.push(next);
					   return item;
					}, []);
					
				console.log('this.dengmiDataEdit.appendPList', this.dengmiDataEdit.appendPList)
				console.log('examList', examList)
				for(let j = 0; j < examList.length; j++) {
					gradeInfoList.push(examList[j].gradeId)
				}
				if(this.dengmiDataEdit.grade.length > 0) {
					for(let j = 0; j < this.dengmiDataEdit.grade.length; j++) {
						gradeInfoList.push(this.dengmiDataEdit.grade[j])
					}
				}
				gradeInfoList = [...new Set(gradeInfoList)]
                examList.forEach((item) => {
                    if (typeof item.examId === "undefined" && typeof item.id !== "undefined") {
                        item.examId = item.id
                        item.id = ''
                    }
                })
				this.$refs[formName].validate((valid) => {
					if(valid) {
						this.disabledBtnState = true;
						this._fet('/school/schoolCerProgramme/save', {
							name: this.dengmiDataEdit.title,
							id: this.dengmiDataEdit.schemeId,
							// gradeInfo: this.dengmiDataEdit.grade,
							gradeInfo: gradeInfoList,
							examList: examList
						}).then((res) => {
							if (res.data.code === '200') {
								this.dengmiDataEdit.examListBox = false;
								this.dengmiDataEdit.examListBoes = false;
								this.dengmiDataEdit.hidenHeight = false;
								this.dengmiDataEdit.title = '';
								this.dengmiDataEdit.grade = [];
								this.dengmiDataEdit.exam = '';
								this.dengmiDataEdit.appendPList = [];
								this.dengmiDataEdit.examEditList = [];
								this.dengmiDataEdit.examSeleteList = [];
								this.dengmiDataEdit.examList = [];
								this.dengmiDataEdit.examDataList = [];
								
								this.$message.success("编辑成功！");
								this.$emit('change', 'YES');
								this.disabledBtnState = false;
							} else if (res.data && res.data.msg) {
								this.$message.error(res.data.msg);
								this.disabledBtnState = false;
							}
						});
					}
				});
				
				
			}
		}
	}
</script>
<style>
	.programme-edit-exam-wrap-option {
		max-width: 444px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	/* 自定义动态插入dom元素 */
	
	/* 关联考试列表弹窗按钮 */
	.exam-wrapers-btn {
		height: 36px;
		line-height: 36px;
		font-size: 14px;
	}
	
	.exam-wrapers-btn .btn-cell {
		float: right;
		cursor: pointer;
	}
	
	.exam-wrapers-btn .confirm-exam-btn {
		color: #43c623;
		margin: 0 16px;
	}
	
	.exam-wrapers-btn .confirm-exam-btn:hover {
		color: #43a620;
	}
	
	.exam-wrapers-btn .cancel-exam-btn {
		color: #f92a2a;
	}
	
	.exam-wrapers-btn .cancel-exam-btn:hover {
		color: #fa5f5f;
	}
</style>
<style scoped lang="scss">
	.programme-edit-pages {}
	
	.programme-edit-pages .el-date-editor {
		width: 100%;
	}
	
	/* 关联考试列表弹窗 */
	.programme-edit-pages .exam-wrap {
		position: relative;
	}
	
	.programme-edit-pages .exam-wrap .el-select {
		display: block;
	}
	
	.programme-edit-pages .exam-wrap .exam-wraper-list {
		position: absolute;
		top: 44px;
		left: 0;
		width: 100%;
		height: 150px;
		border-radius: 4px;
		padding-top: 10px;
		padding-left: 11px;
		padding-right: 9px;
		box-sizing: border-box;
		border: solid 1px #e4e7ed;
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}
	
	.programme-edit-pages .exam-wrap .exam-wraper-list-box {
		width: 100%;
		height: 100%;
		overflow-y: auto;
	}
	
	.programme-edit-pages .exam-wrap .exam-wraper-list .exams-title {
		display: flex;
		align-items: center;
		margin-right: 40px;
	}
	
	.programme-edit-pages .exam-wrap .exam-wraper-list .exams-title .el-icon-error {
		cursor: pointer;
		font-size: 17px;
		color: #b5b7bb;
	}
	
	.programme-edit-pages .exam-wrap .exam-wraper-list .exams-title span{
		width: 158px;
		overflow: hidden; 
		white-space: nowrap; 
		text-overflow: ellipsis; 
	}
	
	.programme-edit-pages .exam-wrap .exam-wraper-list .exam-wraper-list-cell {
		display: flex;
		padding-left: 12px;
		border-radius: 4px;
		background-color: #f5f7fa;
		margin-bottom: 4px;
	}
	
	.programme-edit-pages .exam-wrap .exam-wraper-arrows {
		position: absolute;
		top: -18px;
		left: 36px;
	}
	
	.programme-edit-pages .exam-wrap .exam-wraper-arrows .el-icon-caret-top {
		color: #fff;
	}
	/* 选择指标 */
	.target-list {
		padding: 0 20px;
		box-sizing: border-box;
	}
	
	.target-list .target-list-query {
		width: 50%;
	}
	
	.target-list .target-list-number {
		width: 50%;
		padding-left: 20px;
		box-sizing: border-box;
	}
	
	.target-list .target-list-boes {
		display: flex;
		margin-top: 10px;
	}
	
	.target-list .target-list-boes-row {
		width: 50%;
		height: 370px;
		overflow-y: auto;
		border: 1px solid #e2e2e2;
	}
	
	.target-list .target-list-boes-lt /deep/.el-tree-node__label {
		max-width: 180px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	/* 已选择的指标 */
	.target-list .target-list-boes-rt {
		display: flex;
		flex-wrap: wrap;
		align-content:flex-start;
	}
	
	.target-list .target-list-boes-rt .target-list-boes-rt-row {
		height: 30px;
		line-height: 30px;
		padding: 0 10px; 
		box-sizing: border-box; 
		background-color: #f2f2f2;
		margin-left: 10px;
		margin-top: 10px;
	}
	
	.target-list .target-selete-cell {
		position: relative;
	}
	
	.target-list .target-selete-cell .target-selete-cell-delete {
		position: absolute;
		top: -12px;
		right: -14px;
	}
	
	.target-list .target-selete-cell .target-selete-cell-delete .el-icon-error {
		color: #ff0000;
		cursor: pointer;
	}
</style>